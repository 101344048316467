import React from 'react';

const Contact = () => {
    const clientInformation = {
        name: 'Arno Filmalter',
        mobile: '0410 019268',
        email: 'arnofilmalter@azion.com.au'
    };

    return (
        <div className='d-flex alight-items-center justify-content-center flex-column'>
            <h4>Contact</h4>
            <div className='d-flex justify-content-center'>
                <div className='d-flex flex-column'>
                    <div className='d-flex flex-row'><div className='p-2'>Name:</div><div className='p-2'>{clientInformation.name}</div></div>
                    <div className='d-flex flex-row'><div className='p-2'>Mobile:</div><div className='p-2'>{clientInformation.mobile}</div></div>
                    <div className='d-flex flex-row'><div className='p-2'>Email: </div><div className='p-2'><a href={`mailto:${clientInformation.email}`}>{clientInformation.email}</a></div></div>
                </div>
            </div>
        </div >
    );
};

export default Contact;
